import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';
import { useLocation, useParams } from 'react-router-dom';
import DPOHDashboard from './DPOHDashboard';
import {
    fetchDPOHOrgActivity,
    fetchDPOHOverview,
    fetchDPOHRecentLobbyingActivity,
    fetchDPOHInternalExternalSplit,
    fetchDPOHTopSectors,
    fetchDPOHTopSubjects,
    selectDPOHLobbyingActivity,
    selectDPOHLobbyingActivityLoading,
    selectDPOHOrgActivity,
    selectDPOHOrgActivityLoading,
    selectDPOHOverview,
    selectDPOHOverviewLoading,
    selectDPOHTopPartners,
    selectDPOHTopPartnersLoading,
    selectDPOHTopSectors,
    selectDPOHTopSectorsLoading,
    selectDPOHTopSubjects,
    selectDPOHTopSubjectsLoading,
    selectDPOHExportFail,
    selectDPOHExportLoading,
    selectDPOHSentences,
    selectDPOHSentencesLoading,
    fetchMPHansSentences,
    selectDPOHBio,
    selectDPOHBioLoading,
    fetchMPBio,
} from '../store/DPOHSlice';
import { entityActivity } from '../../../interfaces/organization-entity.interface';
import { mpBiography } from '../interface/dpoh-entity.interface';

const DPOHDataLayer = () => {
    const dispatch = useAppDispatch();

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    // Get the name of the current firms
    const url_params = useParams();

    const institution_id: string | undefined =
        url_params.entityId !== undefined ? url_params.entityId : '';
    const dpoh_name: string | undefined =
        url_params.dpohName !== undefined ? url_params.dpohName : '';

    const location = useLocation();

    const includeHOC: boolean = new URLSearchParams(location.search).get('includeHOC') === 'true';
    const includeMinisterPosition: boolean =
        new URLSearchParams(location.search).get('includeMinisterPosition') === 'true';
    const isMP: boolean = new URLSearchParams(location.search).get('isMP') === 'true';

    const overview: entityOverviews | null = useSelector(selectDPOHOverview);
    const overview_loading: boolean = useSelector(selectDPOHOverviewLoading);

    const bio: mpBiography | null = useSelector(selectDPOHBio);
    const bio_loading: boolean | null = useSelector(selectDPOHBioLoading);

    const sentences: any[] | null = useSelector(selectDPOHSentences);
    const sentencesLoading: boolean = useSelector(selectDPOHSentencesLoading);

    const top_sectors: entityOrg[] | null = useSelector(selectDPOHTopSectors);
    const top_sectors_loading: boolean = useSelector(selectDPOHTopSectorsLoading);

    const top_partners: entityOrg[] | null = useSelector(selectDPOHTopPartners);
    const top_partners_loading: boolean = useSelector(selectDPOHTopPartnersLoading);

    const top_subjects: entityOrg[] | null = useSelector(selectDPOHTopSubjects);
    const top_subjects_loading: boolean = useSelector(selectDPOHTopSubjectsLoading);

    const lobbying_activity: entityLobbyingActivity[] | null = useSelector(
        selectDPOHLobbyingActivity
    );
    const lobbying_activity_loading: boolean = useSelector(selectDPOHLobbyingActivityLoading);

    const org_activity: entityActivity[] | null = useSelector(selectDPOHOrgActivity);
    const org_activity_loading: boolean = useSelector(selectDPOHOrgActivityLoading);
    const export_loading = useSelector(selectDPOHExportLoading);
    const export_fail = useSelector(selectDPOHExportFail);

    useEffect(() => {
        dispatch(
            fetchDPOHOverview({
                inst: institution_id,
                dpoh: dpoh_name,
                date: date,
                includeHOC: includeHOC,
                includeMinisterPosition: includeMinisterPosition,
            })
        );
        dispatch(
            fetchDPOHTopSectors({
                inst: institution_id,
                dpoh: dpoh_name,
                date: date,
                includeHOC: includeHOC,
                includeMinisterPosition: includeMinisterPosition,
            })
        );
        dispatch(
            fetchDPOHInternalExternalSplit({
                inst: institution_id,
                dpoh: dpoh_name,
                date: date,
                includeHOC: includeHOC,
                includeMinisterPosition: includeMinisterPosition,
            })
        );
        dispatch(
            fetchDPOHTopSubjects({
                inst: institution_id,
                dpoh: dpoh_name,
                date: date,
                includeHOC: includeHOC,
                includeMinisterPosition: includeMinisterPosition,
            })
        );
        dispatch(
            fetchDPOHRecentLobbyingActivity({
                inst: institution_id,
                dpoh: dpoh_name,
                date: date,
                includeHOC: includeHOC,
                includeMinisterPosition: includeMinisterPosition,
            })
        );
        dispatch(
            fetchDPOHOrgActivity({
                inst: institution_id,
                dpoh: dpoh_name,
                date: date,
                includeHOC: includeHOC,
                includeMinisterPosition: includeMinisterPosition,
            })
        );
        if (isMP) {
            dispatch(fetchMPBio({ mpFullName: dpoh_name, date: date }));
            dispatch(
                fetchMPHansSentences({ inst: institution_id, mpFullName: dpoh_name, date: date })
            );
        }
    }, [dispatch, date, dpoh_name, institution_id, includeHOC, includeMinisterPosition, isMP]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return (
        <DPOHDashboard
            name={dpoh_name}
            date={date}
            handleDateChange={setDate}
            overview={overview}
            overviewLoading={overview_loading}
            overviewHeaders={overview_headers}
            overviewIndent={['by Consultants']}
            bio={bio}
            bioLoading={bio_loading}
            bumperData={sentences}
            bumperDataLoading={sentencesLoading}
            topPartners={top_partners}
            topPartnersLoading={top_partners_loading}
            topSectors={top_sectors}
            topSectorsLoading={top_sectors_loading}
            topSubjects={top_subjects}
            topSubjectsLoading={top_subjects_loading}
            recentActivity={lobbying_activity}
            recentActivityLoading={lobbying_activity_loading}
            orgActivity={org_activity}
            orgActivityLoading={org_activity_loading}
            isMP={isMP}
            exportLoading={export_loading}
            exportFail={export_fail}
        />
    );
};

export default DPOHDataLayer;
